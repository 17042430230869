.form-wrapper {
    min-height: 100vh;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-card {
    width: 90%;
    border-radius: 7px;
    overflow: hidden;
    margin-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff !important;
}

.form-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.login-form-logo {
    width: 260px;
}

.form-header-text {
    font-size: 1.2rem;
}

@media (min-width: 576px) {
    .form-card {
        width: 70%;
    }
}

@media (min-width: 768px) {
    .form-card {
        width: 50%;
    }
}

@media (min-width: 1024px) {
    .form-card {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .form-card {
        width: 30%;
    }
}