.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.clearfix{
  padding: 15px;
  margin-bottom: 10px;
}
/* a {
  color: #5D6975;
  text-decoration: underline;
} */

#logo {
  text-align: center;
  margin-bottom: 15px;
}

/* #logo img {
  width: 90px;
} */

.invoiceh1 {
  border-top: 1px solid  #5D6975;
  border-bottom: 1px solid  #5D6975;
  color: #5D6975;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: left;
  margin: 0 0 20px 0;
}

#project {
  /* float: left; */
  margin-top: 10px;
}
#secProject {

  float: right;
  display: flex;
  margin-top: 10px;
}

#project3{
  /* float: right; */
  margin-right: 10px;
}
#project span {
  color: #5D6975;
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

#company {
  float: right;
  text-align: right;
}

#project div,
#company div {
  white-space: normal;        
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

table tr:nth-child(2n-1) td {
  background: #F5F5F5;
}

table th,
table td {
  text-align: center;
}

table th {
  padding: 5px 20px;
  color: #5D6975;
  border-bottom: 1px solid #C1CED9;
  white-space: none !important;        
  font-weight: normal;
}

table .service,
table .desc {
  text-align: left;
}

table td {
  padding: 20px;
  text-align: right;
}

table td.service,
table td.desc {
  vertical-align: top;
}

table td.unit,
table td.qty,
table td.total {
  font-size: 1.2em;
}

table td.grand {
  border-top: 1px solid #5D6975;;
}
#notices{
  margin-top: 10px;
}
#notices .notice {
  color: #5D6975;
  font-size: 1.2em;
}

.invoicefooter {
  color: #5D6975;
  width: 100%;
  height: 30px;
  bottom: 0;
  margin-top: 15px;
  border-top: 1px solid #C1CED9;
  padding: 8px 0;
  text-align: center;
}

.dom-table-card-box table td {
  text-align: start !important;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
}
.dom-table-style {
  display: flex;
  justify-content: space-between;
}
.dom-table-style h5:first-child {
  font-weight: 400;
  width: 35%;
}
.dom-table-style h5:last-child {
  width: 65%;
}
.dom-table-style h5 {
  font-weight: 300;
  font-size: 14px;
}
.custom-height-input {
  height: 30px !important;
  font-size: 15px;
}
.label-margin {
  margin: 0px;
}