.customer-footer {
    position: fixed;
    width: 100%;
    background: #f5f5f5;
    color: #000000;
    bottom: 0;
    padding: 20px 10px;
    height: 20%;
    border-top: 2px solid #8888;
}

.combo-detail-heading {
    margin-left: 10px;
}
.confiramtion-btns{
    width: 100px;
    border: 0;
    height:41px;
    margin-top: 15px;
    /* padding: 15px; */
    font-weight: 600;
    border-radius: 5px;
   
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.combo-detail-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin-bottom: 9px;
    padding-top: 1rem;
}
.modal-single-item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #666666;
  }

.green-text {
    color: green;
}


.decrement-btn {
    user-select: none;
    width: 26px;
    height: 24px;
    display: block;
    text-align: center;
    background-color: #fff;
    color: black;
    position: relative;
    /* font-size: 18px; */
    font-weight: bold;
    border: 2px solid gray;
}

.increment-btn {
    user-select: none;
    width: 26px;
    height: 24px;
    display: block;
    position: relative;
    text-align: center;
    background-color: #C00A27;
    color: #fff;
    /* font-size: 12px; */
    font-weight: bold;

}

.inc-icon {
    font-size: x-large;
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.dec-icon {
    font-size: x-large;
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}


.indexing {
    display: flex;
}

.icon-indexing {
    background: #C00A27;
    height: 20px;
    width: 20px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
}

.add-btn {
    cursor: pointer;
}

.btn-text-clr {
    color: #fff !important;
    font-weight: 500;
}

.btn-text-clr-added {
    color: #C00A27 !important;
    font-weight: 500;
}

.added {
    border: 1px solid #C00A27;
    color: #C00A27;
    font-weight: 500;
    text-align: center;
    width: 60px;
    height: 22px;
}

.notAdded {
    background: #C00A27;
    border: 1px solid #C00A27;
    color: #fff;
    font-weight: 500;
    text-align: center;
    width: 60px;
    height: 22px;

}

.lt-item {
    display: flex;
    width: calc(100% - 80px);
    flex-wrap: wrap;
    justify-content: space-between;
}

.multi-addon-row {
    display: flex;
    justify-content: space-between;
}

.row-wrapper {
    display: flex;
    justify-content: space-between;
}

.online {
    display: flex;
    align-items: center;
}

.offline {
    display: flex;
    align-items: center;
}

.online-indicator {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
}

.offline-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
}

.price-box {
    display: flex;
}

.listScroll {
    overflow-y: scroll;
    height: 400px;
}

.customer-detail-section {
    /* display: flex; */
    /* flex-direction: column; */
    border: 1px solid #eaeaea;
    padding: 8px;
}

.error-msg {
    text-align: center;
}

.error-text {
    font-size: 19px;
    font-weight: 600;
    opacity: 0.8;
    margin-left: 1rem;
}

.required-label{
    border-width: 1px;
    --text-opacity: 1;
    color: #888d9a;
    font-size: 0.8rem;
    font-weight: 600;
}

.ComboTabs .nav-item{
    font-size: 13px !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 10px;
}

.ComboTabs .nav-item button{
    margin-top: 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #eeee;
    color: black;
    font-weight: 500;
}
.ComboTabs .nav-tabs .nav-link.active {
    background-color: #007aaf !important;
    border-color: #007aaf !important;
    color:white !important;
}

.ItemTabs .nav-item{
    font-size: 13px !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 10px;
}

.ItemTabs .nav-item button{
    margin-top: 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #eeee;
    color: black;
    font-weight: 500;
}
.ItemTabs .nav-tabs .nav-link.active {
    background-color: #007aaf !important;
    border-color: #007aaf !important;
    color:white !important;
}

.group-box {
    padding: 20px 15px;
    margin-bottom: 15px;
    flex: 0 0 10%; 
    max-width: 10%;
}

.selected-card-border {
    border: 1px solid #C00A27;
}

.card-border-item {
    border: 1px solid #eaeaea;
}

.channel-icons {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    min-width: 30px;
    max-width: 30px;
    border-radius: 50%;
}

.menu-section-padding {
    padding: 5px;
}

.radio-but-margin {
    margin-bottom: 5px;
}

.label-margin {
    margin-bottom: 0 !important;
}

.delivery-methods {
    display: flex;
    align-items: center;
    height: 100%;
}

.payment-method-margin {
    margin-left: 10px;
    margin-bottom: 0px;
}

.tabs-padding {
    padding: 0px 0px 5px 0px !important
}