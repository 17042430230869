table td {
    text-align: left;
    padding: 0.2rem;
    white-space: unset !important;
    font-size: 13px;
  }

.stats-place{
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}
.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: black !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 26px !important;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
/* .css-1hb7zxy-IndicatorsContainer {
  
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
} */

.table td, .table th {
  padding: 0.75rem;
  vertical-align: unset !important;
  border-top: 1px solid #dee2e6;
}
.td-column-function-even-example {
  font-weight: bold;
  color: green;
}

.tr-pending-orders {
  background-color: yellow;
}

.td-column-function-odd-example {
  font-weight: bold;
  color: red;
}

.td-column-function-aggregator-example {
  font-weight: bold;
  color: rgb(236, 109, 130);
}

.tr-transfered-orders {
  background-color: orange;
}

.bottomPagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
.topPagination{
 position: absolute;
  z-index: 990;
}
.zclass{
  z-index: 999 !important;
}

.input-crm-search{
    float: right;
    width: 31%;
    padding: 3px;
    border: 1px solid #ddd;
}
