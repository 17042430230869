.block_unblock_submit{
    display: flex;
    justify-content: right;
    width: 100%;
}
.btn-margin{
    margin-right: 20px
}
.tab-btns-setting{
    display: flex;
    justify-content: space-between;
}